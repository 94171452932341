import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "顺佳教育",
    },
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "顺佳教育",
    },
    component: () => import("../views/Home.vue"),
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "顺佳教育";
  }
  next();
});

export default router;
